<div class="form-container">
    <div class="ui middle aligned centered grid">
      <div class="column">
        <br>
        <br>
        <div *ngIf="requestSubmitted" class="ui container">
          <h1 class="ui left aligned header">Account Request - {{ accountId }}</h1>
          <span>Your request has been submitted to the Principal Investigator. You will receive a confirmation email when the account is approved.</span>
        </div>
        <div *ngIf="!requestSubmitted" class="ui container">
          <h1 *ngIf="!singleRequest" class="ui left aligned header">Account Request</h1>
          <h1 *ngIf="singleRequest" class="ui left aligned header">Account Request - {{ account.Id }}</h1>
          <div class="ui form">
            <br>
            <div *ngIf="showApprovedMessage" class="ui green tertiary inverted segment">
              <div class="field">
                <div>Approved by {{ account.ApprovedBy }} on {{ account.ApprovalDate }}</div>
              </div>
            </div>
            <div *ngIf="showDenialReason" class="ui red tertiary inverted segment">
              <div class="field">
                <div>Reason for Denial: {{ denialReason }}</div>
              </div>
            </div>
            <br *ngIf="showApprovedMessage || showDenialReason">
            <div class="required field">
              <label>First Name</label>
              <input [disabled]="!adminUser ? true : null" name="FirstName" [(ngModel)]="account.FirstName" [ngModelOptions]="{standalone: true}"
                type="text" placeholder="First Name">
              <div *ngIf="formErrors.indexOf('firstName') >= 0" class="form-error">This field is required</div>
              <div *ngIf="formErrors.indexOf('firstNameRules') >= 0" class="form-error">This field must start with a capital letter and not contain any special characters: @ / () " ' `</div>
            </div>
            <div class="required field">
              <label>Last Name</label>
              <input [disabled]="!adminUser ? true : null" [(ngModel)]="account.LastName" [ngModelOptions]="{standalone: true}" type="text"
                placeholder="Last Name" class="field">
              <div *ngIf="formErrors.indexOf('lastName') >= 0" class="form-error">This field is required</div>
              <div *ngIf="formErrors.indexOf('lastNameRules') >= 0" class="form-error">This field must start with a capital letter and not contain any special characters: @ / () " ' `</div>
            </div>
            <div class="required field">
              <label>Email</label>
              <input [disabled]="!adminUser ? true : null" 
                name="Email" 
                [(ngModel)]="account.Email" 
                [ngModelOptions]="{standalone: true}" 
                type="email"
                placeholder="Email">
              <div *ngIf="formErrors.indexOf('email') >= 0" class="form-error">This field is required</div>
              <div *ngIf="formErrors.indexOf('emailExists') >= 0" class="form-error">There is already an account associated with this email.</div>
              <div *ngIf="formErrors.indexOf('emailCharacters') >= 0" class="form-error">Email address must contain '@' and '.' symbols.</div>
            </div>
            <div class="required field">
              <label>Company</label>
              <input [disabled]="!adminUser ? true : null" [(ngModel)]="account.Company" [ngModelOptions]="{standalone: true}" type="text"
                placeholder="Company" (keydown.Tab)="toggleDirectorySearchModal('pi')">
              <div *ngIf="formErrors.indexOf('company') >= 0" class="form-error">This field is required</div>
            </div>
            <div class="grouped fields">
              <label>System(s) Requesting Access To</label>
              <div class="field" *ngFor="let system of systemOptions">
                <sui-checkbox [isDisabled]="!adminUser ? true : null" [(ngModel)]="system.checked" name="systemOptions" (ngModelChange)="onCheckboxSelect(system, $event)">
                  {{ system.Name }}
                </sui-checkbox>
              </div>
            </div>
            <div (keydown.Tab)="toggleDirectorySearchModal('pi')" (keyup.enter)="toggleDirectorySearchModal('pi')"
              (click)="toggleDirectorySearchModal('pi')" class="required field">
              <label>GSU PI Name</label>
              <input [disabled]="!adminUser ? true : null" [(ngModel)]="account.PI" [ngModelOptions]="{standalone: true}"
                type="text" placeholder="GSU PI Name">
              <div *ngIf="formErrors.indexOf('pi') >= 0" class="form-error">This field is required</div>
              <input [hidden]="true" [(ngModel)]="account.PIEmail" [ngModelOptions]="{standalone: true}" type="text"
                placeholder="PI E-mail" #piEmail>
            </div>
            <div *ngIf="showCDERDetails">
              <div class="required field">
                <label>Country</label>
                <sui-select [isDisabled]="!adminUser ? true : null" 
                  [(ngModel)]="account.Country" 
                  class="selection" 
                  #countryDropdown>
                  <sui-select-option *ngFor="let country of countries" [value]="country">
                  </sui-select-option>
                </sui-select>
                <div *ngIf="formErrors.indexOf('country') >= 0" class="form-error">This field is required</div>
              </div>
              <div class="inline fields">
                <div class="field">
                  <label>Please indicate your role:</label>
                </div>
                <div class="field">
                  <div class="ui radio checkbox">
                    <input type="radio" 
                      value="Teacher" 
                      name="TeacherYN" 
                      [(ngModel)]="account.TeacherYN" 
                      #TeacherYN="ngModel" required >
                    <label>Teacher</label>
                  </div>
                </div>
                <div class="field">
                  <div class="ui radio checked checkbox">
                    <input type="radio" 
                      value="Student" 
                      name="TeacherYN" 
                      [(ngModel)]="account.TeacherYN" 
                      #TeacherYN="ngModel" required>
                    <label>Student</label>
                  </div>
                </div>
                
              </div>
              <small *ngIf="!TeacherYN.valid" class="text-danger">
                Yes or No value is required.
              </small>
   
              <div class="required field" *ngIf="account.TeacherYN === 'Student'">
                <label>Teacher Name</label>
                <input [disabled]="!adminUser ? true : null" 
                  (keydown.Tab)="toggleDirectorySearchModal('teacher')" (keyup.enter)="toggleDirectorySearchModal('teacher')"
                  (click)="toggleDirectorySearchModal('teacher')"
                  [(ngModel)]="account.Teacher" 
                  [ngModelOptions]="{standalone: true}" 
                  type="text"
                  placeholder="Teacher Name">
                <div *ngIf="formErrors.indexOf('teacher') >= 0" class="form-error">This field is required</div>
              </div>
            </div>
            
            <br>
            <div class="ui divider"></div>
            <br> 
            <button *ngIf="!showAdminButtons" (click)="checkEmail()" class="ui button primary">Submit</button>
            <!-- Admin buttons -->
            <div *ngIf="showAdminButtons">
              <button (click)="backToDashboard()" class="ui button">Go Back</button>
              <button *ngIf="adminUser" (click)="updateAccount()" class="ui button">Update</button>
              <button *ngIf="!approved" (click)="approveAccount()" class="ui button green">Approve</button>
              <button *ngIf="!approved" (click)="toggleDenyModal()" class="ui button red">Deny</button>
            </div>
            <br>
          </div>
        </div>
        <br>
        <br>
      </div>
    </div> 
</div>

<!-- deny modal -->
<sui-modal *ngIf="denyModalVisible" size="tiny" (dismissed)="toggleDenyModal()" #denyModal>
  <div class="header">Reason for Denying Request</div>
  <div class="content">
    <h4 class="ui header">Enter reason for denying request below:</h4>
    <div class="ui form">
      <div class="field">
        <textarea rows="5" [(ngModel)]="denialReason"></textarea>
      </div>
    </div>
  </div>
  <div class="actions">
    <button class="ui button" (click)="toggleDenyModal()">Cancel</button>
    <button class="ui red button" (click)="denyAccount()">Submit Denial</button>
  </div>
</sui-modal>

<app-directory-search-modal *ngIf="directorySearchModalVisible" (selectUserResult)="updateFieldsWithUserInfo($event)" (closeSearchModal)="toggleDirectorySearchModal()"></app-directory-search-modal>
