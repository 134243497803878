import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DirectorySearchService } from '../../services/directory-search.service';
import { LoaderService } from '../../services/loader.service';
import { DirectoryResult } from '../../../models/directoryResult';
import { convertToObject } from 'typescript';

@Component({
  selector: 'app-directory-search-modal',
  templateUrl: './directory-search-modal.component.html',
  styleUrls: ['./directory-search-modal.component.css']
})
export class DirectorySearchModalComponent implements OnInit, OnDestroy {
  @Output() public selectUserResult = new EventEmitter();
  @Output() public closeSearchModal = new EventEmitter();

  public searchTerm: string;
  public searchResults: Array<DirectoryResult>;
  public selectedUser: DirectoryResult;
  public selectedField: string;
  public searchMessage = false;
  public isLoading: boolean;
  private loaderSubscription;

  constructor(
    private _directorySearchService: DirectorySearchService,
    private _loaderService: LoaderService
  ) { }

  public ngOnInit() {
    this.loaderSubscription = this._loaderService.directoryLoadingUpdate.subscribe(
      loading => {
        this.isLoading = loading;
      }
    );
  }

  public ngOnDestroy() {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }

  public searchAccounts() {
    this.searchMessage = false;
    this._directorySearchService.searchUsers(this.searchTerm).subscribe(
      response => {
        const accounts = response;

        if (Array.isArray(accounts) && accounts.length > 0) {
          this.searchResults = accounts.filter((result: DirectoryResult) => {
            if (!result.mail.includes('@student.gsu.edu')) {
              result.displayName = result.displayName;
              if(result.jobTitle.includes('Graduate')) {
                // if Graduate then update email to '@student.gsu.edu'
                result.mail = result.mail.replace(/@[^.]+/, '@student.gsu.edu');
              } else {
                result.mail = result.mail;
              }
             
              return result;
            }

          });
          
        } else {
          this.searchResults = undefined;
          this.searchMessage = true;

        }

      },
      error => {
        console.log('search by name error: ', error);
        this.searchResults = undefined;
        this.searchMessage = true;
      }
    );
  }

  public selectUser(user) {
    this.selectUserResult.emit(user);
  }

  public closeModal() {
    this.closeSearchModal.emit(true);
  }
}
